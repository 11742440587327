import { DirectIndexType } from "../generated/graphql";

export enum DirectIndexingTypePath {
  Sp500 = "sp-500",
  SpInfoTech = "sp-info-tech",
  CrspLargeCap = "crsp-large-cap",
  CrspMidCap = "crsp-mid-cap",
  CrspSmallCap = "crsp-small-cap",
  CrspTotalMarket = "crsp-total-market",
  Smh = "smh",
  CrspIssLargeCapEsg = "crsp-iss-large-cap-esg",
  Russell_1000 = "russell-1000",
  Russell_2000 = "russell-2000",
  Russell_3000 = "russell-3000",
  SpAdrDm = "sp-adr-dm",
  SpShariah = "sp-shariah",
}

export const DI_TYPE_TO_URL_MAP = new Map<
  DirectIndexType,
  DirectIndexingTypePath
>([
  [DirectIndexType.Sp500, DirectIndexingTypePath.Sp500],
  [DirectIndexType.SpInfoTech, DirectIndexingTypePath.SpInfoTech],
  [DirectIndexType.SpAdrDm, DirectIndexingTypePath.SpAdrDm],
  [DirectIndexType.SpShariah, DirectIndexingTypePath.SpShariah],
  [DirectIndexType.CrspLargeCap, DirectIndexingTypePath.CrspLargeCap],
  [DirectIndexType.CrspMidCap, DirectIndexingTypePath.CrspMidCap],
  [DirectIndexType.CrspSmallCap, DirectIndexingTypePath.CrspSmallCap],
  [DirectIndexType.CrspTotalMarket, DirectIndexingTypePath.CrspTotalMarket],
  [DirectIndexType.Smh, DirectIndexingTypePath.Smh],
  [
    DirectIndexType.CrspIssLargeCapEsg,
    DirectIndexingTypePath.CrspIssLargeCapEsg,
  ],
  [DirectIndexType.Russell_1000, DirectIndexingTypePath.Russell_1000],
  [DirectIndexType.Russell_2000, DirectIndexingTypePath.Russell_2000],
  [DirectIndexType.Russell_3000, DirectIndexingTypePath.Russell_3000],
]);

export const DI_URL_TO_TYPE_MAP: Record<
  DirectIndexingTypePath,
  DirectIndexType
> = {
  [DirectIndexingTypePath.Sp500]: DirectIndexType.Sp500,
  [DirectIndexingTypePath.SpInfoTech]: DirectIndexType.SpInfoTech,
  [DirectIndexingTypePath.SpAdrDm]: DirectIndexType.SpAdrDm,
  [DirectIndexingTypePath.SpShariah]: DirectIndexType.SpShariah,
  [DirectIndexingTypePath.CrspLargeCap]: DirectIndexType.CrspLargeCap,
  [DirectIndexingTypePath.CrspMidCap]: DirectIndexType.CrspMidCap,
  [DirectIndexingTypePath.CrspSmallCap]: DirectIndexType.CrspSmallCap,
  [DirectIndexingTypePath.CrspTotalMarket]: DirectIndexType.CrspTotalMarket,
  [DirectIndexingTypePath.Smh]: DirectIndexType.Smh,
  [DirectIndexingTypePath.CrspIssLargeCapEsg]:
    DirectIndexType.CrspIssLargeCapEsg,
  [DirectIndexingTypePath.Russell_1000]: DirectIndexType.Russell_1000,
  [DirectIndexingTypePath.Russell_2000]: DirectIndexType.Russell_2000,
  [DirectIndexingTypePath.Russell_3000]: DirectIndexType.Russell_3000,
};

import {
  BUSINESS_TIMEZONE,
  DateOnly,
  Percentage,
  startDateFromPeriod,
} from "@frec-js/common";
import Decimal from "decimal.js";
import { useMemo } from "react";

import { useActivityData } from "../activities";
import {
  ActivityFilterType,
  ActivityStatus,
  ComparisonPortfolioSnapshotFragment,
  DirectIndexAccountWeights,
  PerformanceDataPeriod,
  PortfolioSnapshotFragment,
  ScheduledDepositType,
  SimpleActivity,
  useActiveScheduledDepositConfigsQuery,
  useDemoActiveScheduledDepositConfigsQuery,
  useDemoDirectIndexPerformanceDataV2Query,
  useDemoGetDirectIndexWeightsForAccountQuery,
  useDirectIndexPerformanceDataV2Query,
  useGetDirectIndexWeightsForAccountQuery,
} from "../generated/graphql";

export const useGetDirectIndexingWeightsForAccount = (
  subAccountId?: string,
  option?: {
    isDemo?: boolean;
  }
) => {
  const { isDemo } = option ?? { isDemo: false };

  const result = (
    isDemo
      ? useDemoGetDirectIndexWeightsForAccountQuery
      : useGetDirectIndexWeightsForAccountQuery
  )({
    variables: {
      subAccountId: subAccountId ?? "",
    },
    skip: !subAccountId,
    fetchPolicy: "cache-and-network",
  });

  return {
    loading: result.loading,
    directIndexWeightsForAccount: result.data
      ?.getDirectIndexWeightsForAccount as
      | DirectIndexAccountWeights
      | undefined,
  };
};

// Similar to the graphql version, but with Percentages
export type UseDirectIndexPerformanceDataResult = {
  taxLossesHarvested?: Decimal;
  netDeposits?: Decimal;
  cumulativeNetDeposits?: { date: DateOnly; value: Decimal }[];
  directIndexHistory?: PortfolioSnapshotFragment[];
  comparisonPortfolioHistory?: ComparisonPortfolioSnapshotFragment[];
  moneyWeightedReturn?: Percentage;
  timeWeightedReturn?: Percentage;
  simpleReturn?: Percentage;
  shortTermLosses?: Decimal;
  shortTermGains?: Decimal;
  longTermLosses?: Decimal;
  longTermGains?: Decimal;
  shortWashSalesDisallowed?: Decimal;
  longWashSalesDisallowed?: Decimal;
  netShortTermLosses?: Decimal;
  netLongTermLosses?: Decimal;
  estimatedTaxSavings?: Decimal;
  loading: boolean;
};

type UseDirectIndexingPerformanceDataParams = {
  subAccountId: string;
  period: PerformanceDataPeriod;
  skip?: boolean;
  isDemo?: boolean;
};
export const useDirectIndexingPerformanceData = ({
  subAccountId,
  period: periodInput,
  skip = false,
  isDemo = false,
}: UseDirectIndexingPerformanceDataParams):
  | UseDirectIndexPerformanceDataResult
  | undefined => {
  const performanceDataQuery = (
    isDemo
      ? useDemoDirectIndexPerformanceDataV2Query
      : useDirectIndexPerformanceDataV2Query
  )({
    variables: {
      subAccountId,
      performanceDataPeriod: periodInput,
    },
    skip,
  });
  const data = performanceDataQuery.data?.getDirectIndexPerformanceDataV2;
  const directIndexHistory = useMemo(() => {
    const startDate = startDateFromPeriod(periodInput);
    return (
      data?.directIndexHistory.filter(
        (d) =>
          !DateOnly.fromDateTz(new Date(d.date), BUSINESS_TIMEZONE).isBefore(
            startDate
          )
      ) ?? undefined
    );
  }, [data?.directIndexHistory, periodInput]);
  const comparisonPortfolioHistory = useMemo(() => {
    const startDate = startDateFromPeriod(periodInput);
    return (
      data?.comparisonPortfolioHistory?.filter(
        (d) =>
          !DateOnly.fromDateTz(d.date, BUSINESS_TIMEZONE).isBefore(startDate)
      ) ?? undefined
    );
  }, [data?.comparisonPortfolioHistory, periodInput]);

  const periodData = data?.periods.find((p) => p.period === periodInput);
  const performanceDataFromServer = useMemo(
    () => ({
      directIndexHistory,
      comparisonPortfolioHistory,
      cumulativeNetDeposits: periodData?.cumulativeNetDeposits ?? undefined,
      taxLossesHarvested: periodData?.taxLossesHarvested ?? undefined,
      netDeposits: periodData?.netDeposits ?? undefined,
      moneyWeightedReturn: periodData?.moneyWeightedReturnPercent
        ? new Percentage({ percent: periodData.moneyWeightedReturnPercent })
        : undefined,
      timeWeightedReturn: periodData?.timeWeightedReturnPercent
        ? new Percentage({ percent: periodData.timeWeightedReturnPercent })
        : undefined,
      simpleReturn: periodData?.simpleReturnPercent
        ? new Percentage({ percent: periodData.simpleReturnPercent })
        : undefined,
      shortTermLosses: periodData?.shortTermLosses ?? undefined,
      shortTermGains: periodData?.shortTermGains ?? undefined,
      longTermLosses: periodData?.longTermLosses ?? undefined,
      longTermGains: periodData?.longTermGains ?? undefined,
      netShortTermLosses: periodData?.netShortTermLosses ?? undefined,
      netLongTermLosses: periodData?.netLongTermLosses ?? undefined,
      estimatedTaxSavings: periodData?.estimatedTaxSavings ?? undefined,
      loading: performanceDataQuery.loading,
    }),
    [
      comparisonPortfolioHistory,
      directIndexHistory,
      performanceDataQuery,
      periodData,
    ]
  );
  return performanceDataFromServer;
};

export const DI_ACTIVITY_FILTER = [
  ActivityFilterType.Withdrawal,
  ActivityFilterType.Deposit,
  ActivityFilterType.IntraAccountCashTransfer,
  ActivityFilterType.DirectIndexLiquidationRequest,
  ActivityFilterType.DirectIndexTradeExecutions,
  ActivityFilterType.DirectIndexFee,
  ActivityFilterType.IntraAccountStockTransfer,
  ActivityFilterType.Dividends,
];

type UseDirectIndexActivitiesArgs = {
  clearingAccountId: string;
  subAccountId?: string;
  filter?: ActivityFilterType[];
  injection?: SimpleActivity;
  size?: number;
  isDemo?: boolean;
};
export const useDirectIndexActivities = ({
  clearingAccountId,
  subAccountId,
  filter = DI_ACTIVITY_FILTER,
  injection,
  size,
  isDemo,
}: UseDirectIndexActivitiesArgs) => {
  const {
    activity: pendingActivities,
    loading: pendingLoading,
    hasMore: pendingHasMore,
    handleLoadMore: pendingOnLoadMore,
  } = useActivityData({
    clearingAccountId,
    status: ActivityStatus.Pending,
    filter,
    subAccountId,
    size,
    skip: !subAccountId,
    injection,
    isDemo,
  });
  const {
    activity: completedActivities,
    loading: completedLoading,
    hasMore: completedHasMore,
    handleLoadMore: completedOnLoadMore,
  } = useActivityData({
    clearingAccountId,
    status: ActivityStatus.Complete,
    filter,
    subAccountId,
    size,
    skip: !subAccountId,
    injection,
    isDemo,
  });

  return useMemo(
    () => ({
      pendingActivities,
      pendingHasMore,
      pendingLoading,
      pendingOnLoadMore,
      completedActivities,
      completedHasMore,
      completedLoading,
      completedOnLoadMore,
      activities: [...pendingActivities, ...completedActivities].sort(
        (a, b) => (b.date?.getTime() ?? 0) - (a.date?.getTime() ?? 0)
      ),
      loading: pendingLoading || completedLoading,
      done: !pendingHasMore && !completedHasMore,
    }),
    [
      completedActivities,
      completedHasMore,
      completedLoading,
      completedOnLoadMore,
      pendingActivities,
      pendingHasMore,
      pendingLoading,
      pendingOnLoadMore,
    ]
  );
};

type UseDirectIndexingActiveDepositConfigsArgs = {
  subAccountId?: string;
  clearingAccountId: string;
  isDemo?: boolean;
};
export const useDirectIndexingActiveDepositConfigs = ({
  subAccountId,
  clearingAccountId,
  isDemo,
}: UseDirectIndexingActiveDepositConfigsArgs) => {
  const { data, loading, refetch } = (
    isDemo
      ? useDemoActiveScheduledDepositConfigsQuery
      : useActiveScheduledDepositConfigsQuery
  )({
    variables: {
      clearingAccountId,
      subAccountId,
    },
    skip: !clearingAccountId,
  });

  // Should only be one recurring deposit config per sub account.
  // The keyFields in the cache ensure this is so.
  const recurringDepositConfig = subAccountId
    ? data?.clearingAccount?.activeScheduledDepositConfigs?.find(
        (config) =>
          config.type === ScheduledDepositType.PeriodicDeposit &&
          config.subAccountId === subAccountId
      )
    : undefined;

  const allConfigs = data?.clearingAccount?.activeScheduledDepositConfigs;
  return useMemo(
    () => ({
      allConfigs,
      recurringDepositConfig,
      loading,
      refetch,
    }),
    [allConfigs, loading, recurringDepositConfig, refetch]
  );
};

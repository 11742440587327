import Decimal from "decimal.js";

import { FrecProductName } from "../common";
import { UserReferralDeal } from "../generated/graphql";
import { ZERO } from "../utils";

/**
 * Used when determining what the referral deal should be when creating
 * invitation code records for users
 *
 * Changing this value will change the default referral deal for new users,
 * but existing users will need to be updated in the DB
 */
export const CURRENT_REFERRAL_DEAL =
  UserReferralDeal.DiTwoHundredFiftyDollarsEachTreasuryTwentyBps;

/**
 * Important: Instead of making changes to deal terms, we
 * should prefer creating new enum arms and new deal terms
 */
export const UserReferralDealTerms = {
  [UserReferralDeal.DiOneHundredDollarsTreasuryTwentyBps]: {
    benefactors: {
      invitedUser: false,
      referringUser: true,
    },
    [FrecProductName.Treasury]: {
      /**
       * Users who refer someone successfully will get 20bps of increased
       * yield on their DI account for 1 month. This is capped at 12
       * redemptions, which do not have to be consecutive and do not
       * run concurrently.
       */
      basisPoints: new Decimal(20),
      duration: {
        days: 30,
      },
      maxRedemptions: 12,
    },
    [FrecProductName.DirectIndexing]: {
      /**
       * Users who refer someone successfully will get $100
       * deposited into their cash account once the invitee
       * funds their DI account
       */
      depositAmount: new Decimal(100),
      // No delay
      awardGivenAfterDIFunded: { days: 0, months: 0 },
    },
  },
  [UserReferralDeal.DiTwoHundredFiftyDollarsEachTreasuryTwentyBps]: {
    benefactors: {
      invitedUser: true,
      referringUser: true,
    },
    [FrecProductName.Treasury]: {
      /**
       * Users who refer someone successfully will get 20bps of increased
       * yield on their DI account for 1 month. This is capped at 12
       * redemptions, which do not have to be consecutive and do not
       * run concurrently.
       */
      basisPoints: new Decimal(20),
      duration: {
        days: 30,
      },
      maxRedemptions: 12,
    },
    [FrecProductName.DirectIndexing]: {
      /**
       * Users who refer someone successfully will get $250
       * deposited into their cash account once the invitee
       * funds their DI account
       */
      depositAmount: new Decimal(250),

      /**
       * User has to have a funded DI for 3 months before
       * either party gets the reward
       *
       * We can say "in 3 months" in our materials but when enforcing this we should
       * use the daily duration of 90 days
       */
      awardGivenAfterDIFunded: { days: 90, months: 3 },
    },
  },
  [UserReferralDeal.InfluencerDiTwoHundredFiftyDollars]: {
    benefactors: {
      invitedUser: true,
      referringUser: false,
    },
    [FrecProductName.DirectIndexing]: {
      /**
       * A user who signs up with an influencer's code will get $250. The
       * influencer does not get anything for this deal.
       */
      depositAmount: new Decimal(250),

      /**
       * User has to have a funded DI for 6 months before
       * either party gets the reward
       *
       * We can say "in 6 months" in our materials but when enforcing this we should
       * use the daily duration of 180 days
       */
      awardGivenAfterDIFunded: { days: 180, months: 6 },
    },
  },
  [UserReferralDeal.HamptonMemberDiTwoHundredFiftyDollars]: {
    benefactors: {
      invitedUser: true,
      referringUser: false,
    },
    [FrecProductName.DirectIndexing]: {
      /**
       * A user who signs up with an influencer's code will get $250. The
       * influencer does not get anything for this deal.
       */
      depositAmount: new Decimal(250),

      /**
       * User has to have a funded DI for 3 months before
       * either party gets the reward
       *
       * We can say "in 3 months" in our materials but when enforcing this we should
       * use the daily duration of 90 days
       */
      awardGivenAfterDIFunded: { days: 90, months: 3 },
    },
  },
  [UserReferralDeal.HnwDiFiveHundredDollars]: {
    benefactors: {
      invitedUser: true,
      referringUser: false,
    },
    [FrecProductName.DirectIndexing]: {
      /**
       * A user who signs up with an influencer's code will get $500
       */
      depositAmount: new Decimal(500),

      /**
       * User has to have a funded DI for 3 months before
       * either party gets the reward
       *
       * We can say "in 3 months" in our materials but when enforcing this we should
       * use the daily duration of 90 days
       */
      awardGivenAfterDIFunded: { days: 90, months: 3 },
    },
  },
  [UserReferralDeal.HnwTieredTotalAssetBonus]: {
    benefactors: {
      invitedUser: true,
      referringUser: false,
    },
    [FrecProductName.DirectIndexing]: {
      /**
       * A user who signs up gets a reward that is a function of the assets they
       * transfer; ZERO for now till we decide to actually implement this type
       * of a deal.
       */
      depositAmount: ZERO,

      /**
       * This is a front loaded reward. Given right away with a claw back.
       */
      awardGivenAfterDIFunded: { days: 30, months: 1 },
    },
  },
  [UserReferralDeal.FrecNewYearPromo_2024]: {
    benefactors: {
      invitedUser: true,
      referringUser: false,
    },
    [FrecProductName.DirectIndexing]: {
      /**
       * A user who signs up during the promo will get $300
       */
      depositAmount: new Decimal(300),

      /**
       * Terms: https://docs.frec.com/frec-2024-new-year-bonus.pdf
       */
      awardGivenAfterDIFunded: { days: 180, months: 6 },
    },
  },
} as const;
